var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gift"},[_c('div',{staticClass:"header"},[_c('mi-title',{attrs:{"title":_vm.$t('gift.title')}}),_c('div',{staticClass:"status"},[_c('span',{staticClass:"item",class:_vm.status === 1 && 'active',on:{"click":function($event){return _vm.toggleStatus(1)}}},[_vm._v(_vm._s(_vm.$t('gift.usable')))]),_c('span',{staticClass:"item",class:_vm.status === 2 && 'active',on:{"click":function($event){return _vm.toggleStatus(2)}}},[_vm._v(_vm._s(_vm.$t('gift.invalid')))]),_c('span',{staticClass:"item",class:_vm.status === 3 && 'active',on:{"click":function($event){return _vm.toggleStatus(3)}}},[_vm._v(_vm._s(_vm.$t('gift.used')))])])],1),_c('div',{ref:"tab",staticClass:"cards"},[(_vm.cardList.length)?_c('div',{staticClass:"card-list"},_vm._l((_vm.cardList),function(ref){
        var titleValue = ref.titleValue;
        var giftcardId = ref.giftcardId;
        var giftcardValue = ref.giftcardValue;
        var endTime = ref.endTime;
        var activityName = ref.activityName;
        var orderFeeRequired = ref.orderFeeRequired;
return _c('div',{key:giftcardId,staticClass:"item",class:_vm.cardStatus.item},[_c('h2',{staticClass:"count"},[_vm._v(_vm._s(_vm.$tc('common.accuout', titleValue)))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.$t('gift.term'))+" "+_vm._s(endTime))]),_c('div',{staticClass:"desc"},[_c('p',{staticClass:"desc-item"},[_vm._v(" · "+_vm._s(activityName)+_vm._s(_vm.$tc('gift.give', giftcardValue))+" ")]),(orderFeeRequired > 0)?_c('p',{staticClass:"desc-item"},[_vm._v(" · "+_vm._s(_vm.$tc('gift.overflow', orderFeeRequired))+" ")]):_c('p',{staticClass:"desc-item"},[_vm._v("· "+_vm._s(_vm.$t('gift.desc')))])]),_c('span',{class:['icon', _vm.cardStatus.icon]})])}),0):_c('div',{staticClass:"not-data"},[_vm._v(_vm._s(_vm.$t('common.notData')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }