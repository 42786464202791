<template>
  <div class="gift">
    <div class="header">
      <mi-title :title="$t('gift.title')"></mi-title>
      <div class="status">
        <span
          class="item"
          :class="status === 1 && 'active'"
          @click="toggleStatus(1)"
          >{{ $t('gift.usable') }}</span
        >
        <span
          class="item"
          :class="status === 2 && 'active'"
          @click="toggleStatus(2)"
          >{{ $t('gift.invalid') }}</span
        >
        <span
          class="item"
          :class="status === 3 && 'active'"
          @click="toggleStatus(3)"
          >{{ $t('gift.used') }}</span
        >
      </div>
    </div>

    <div class="cards" ref="tab">
      <div class="card-list" v-if="cardList.length">
        <div
          class="item"
          v-for="{
            titleValue,
            giftcardId,
            giftcardValue,
            endTime,
            activityName,
            orderFeeRequired
          } in cardList"
          :key="giftcardId"
          :class="cardStatus.item"
        >
          <h2 class="count">{{ $tc('common.accuout', titleValue) }}</h2>
          <div class="time">{{ $t('gift.term') }} {{ endTime }}</div>
          <div class="desc">
            <p class="desc-item">
              · {{ activityName }}{{ $tc('gift.give', giftcardValue) }}
            </p>
            <p class="desc-item" v-if="orderFeeRequired > 0">
              · {{ $tc('gift.overflow', orderFeeRequired) }}
            </p>
            <p class="desc-item" v-else>· {{ $t('gift.desc') }}</p>
          </div>
          <span :class="['icon', cardStatus.icon]"></span>
        </div>
      </div>
      <div v-else class="not-data">{{ $t('common.notData') }}</div>
    </div>
  </div>
</template>

<script>
import { checkPrivacy } from '@/mixins'
import dayjs from 'dayjs'
import { MiTitle } from '@/components'
import { unitFormat } from '@/utils'
import { sub } from '@lzwme/asmd-calc'

export default {
  mixins: [checkPrivacy],
  components: {
    MiTitle
  },
  data() {
    return {
      status: 1,
      giftData: {}
    }
  },
  computed: {
    cardStatus() {
      let item = 'use'
      const { status } = this

      if (status === 2) {
        item = 'used'
      } else if (status === 3) {
        item = 'invalid'
      }

      return {
        item,
        icon: `${item}-icon`
      }
    },
    cardList() {
      return this.giftData[this.status] || []
    }
  },
  async created() {
    await this.checkPrivacy()
    this.getGiftCard()
  },
  methods: {
    toggleStatus(status) {
      this.status = status
      this.getGiftCard()
      this.$refs.tab.scrollTo(0, 0)
    },
    async getGiftCard() {
      const { status, giftData } = this

      if (giftData[status]) return

      const { rows } = await this.$http.getGiftCard({
        pageNo: 1,
        pageSize: 50,
        giftcardStatus: status
      })
      const list = rows.map(
        ({
          activityName,
          giftcardValue,
          availableValue,
          giftcardEndTime,
          orderFeeRequired
        }) => {
          giftcardValue = unitFormat(giftcardValue)
          availableValue = unitFormat(availableValue)
          orderFeeRequired = unitFormat(orderFeeRequired)

          const titleValue =
            status < 3 ? availableValue : sub(giftcardValue, availableValue)
          const endTime = dayjs(giftcardEndTime).format('YYYY-MM-DD HH:mm:ss')

          return {
            endTime,
            titleValue,
            activityName,
            giftcardValue,
            orderFeeRequired
          }
        }
      )

      this.giftData = {
        ...giftData,
        [status]: list
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gift {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    background-color: $white-color;
    .status {
      padding: 43px 80px 66px;
      .item {
        font-size: 52px;
        color: $color-text-tertiary;
        margin-right: 66px;
      }
      .active {
        color: $title-color-text;
      }
    }
  }

  .cards {
    flex: 1;
    overflow-y: auto;
    .card-list {
      padding: 0 37px 66px;
      .item {
        padding: 45px 44px;
        height: 392px;
        background-color: $bgc-block;
        @include round(24px);
        margin-bottom: 30px;
        position: relative;
        .count {
          font-weight: 600;
          font-size: 50px;
          color: $theme-text-color;
          font-family: Mitype;
        }
        .time {
          font-size: 36px;
          color: $color-text-secondary;
          margin: 16px 0 32px;
        }
        .desc {
          border-top: 1px dashed #ccc;
          padding-top: 30px;
          &-item {
            font-size: 36px;
            color: $color-text-tertiary;
          }
        }
      }
      .used,
      .invalid {
        color: rgba(0, 0, 0, 0.3);
        background-color: #f7f7f7;
        .count,
        .time,
        .desc-item {
          color: rgba(0, 0, 0, 0.3);
        }
        .icon {
          position: absolute;
          right: 0;
          top: 0;
          width: 177px;
          height: 171px;
        }
      }
      .used {
        &-icon {
          @include bg-image('used');
        }
      }
      .invalid {
        &-icon {
          @include bg-image('invalid');
        }
      }
      .use-icon {
        width: 132px;
        height: 68px;
        position: absolute;
        top: 40px;
        right: 0;
        @include bg-image('use');
      }
    }
  }
  .not-data {
    @include bg-image('not');
    @extend .mi-not-data;
  }
}
</style>
